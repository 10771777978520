import cx from 'classnames'
import dynamic from 'next/dynamic'

import { type SanityMaskedCircleSection } from '@data/sanity/queries/types/modules'

const ComplexPortableText = dynamic(
  () => import('@components/complex-portable-text')
)
const Photo = dynamic(() => import('@components/photo'))

type MaskedCircleSectionProps = SanityMaskedCircleSection

const MaskedCircleSection = ({
  content,
  image,
  colors,
}: MaskedCircleSectionProps) => {
  const colorClasses = {
    backgroundPrimary: `bg-${colors?.backgroundPrimary ?? 'yellow'}`,
    backgroundSecondary: `bg-${colors?.backgroundSecondary ?? 'green-dark'}`,
    text: `text-${colors?.text ?? 'current'}`,
  }

  return (
    <section
      className={cx(
        colorClasses.text,
        'relative overflow-hidden z-[1]',
        `sm:before:${colorClasses.backgroundPrimary} sm:before:absolute sm:before:left-[-100%] sm:before:top-0 sm:before:bottom-0 sm:before:w-[150%] sm:before:z-[-1]`,
        `sm:after:${colorClasses.backgroundSecondary} sm:after:absolute sm:after:right-[-100%] sm:after:top-0 sm:after:bottom-0 sm:after:w-[150%] sm:after:z-[-1]`
      )}
    >
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-[75%,25%] lg:grid-cols-[60%,40%] overfow-hidden">
          <div
            className={cx(
              `${colorClasses.backgroundPrimary} flex items-center py-20 relative sm:pr-28`,
              `before:${colorClasses.backgroundPrimary} before:absolute before:inset-0 before:left-[-50%] before:w-[200%] before:z-[-1]`,
              'sm:before:content-[none]'
            )}
          >
            {content && (
              <ComplexPortableText
                content={content}
                className="rc sm:max-w-md xl:max-w-prose"
              />
            )}
          </div>
          <div
            className={cx(
              `${colorClasses.backgroundSecondary} py-10 sm:py-20 relative`,
              `before:${colorClasses.backgroundSecondary} before:absolute before:inset-0 before:left-[-50%] before:w-[200%] before:z-[-1]`,
              'sm:before:content-[none]'
            )}
          >
            <div
              className={cx(
                'mx-auto -mt-28 sm:mt-auto sm:-ml-24 lg:-ml-28 sm:top-[50%] sm:translate-y-[-50%]',
                'w-[320px] h-[320px] xs:w-[400px] xs:h-[400px] md:w-[450px] md:h-[450px] lg:w-[570px] lg:h-[570px]',
                'relative rounded-full overflow-hidden',
                `before:w-full before:h-[4.5rem] before:absolute before:left-0 before:right-0 before:top-0 before:${colorClasses.backgroundSecondary} before:z-[1]`,
                'sm:before:h-full sm:before:w-24 lg:before:w-28 sm:before:bottom-0',
                {
                  [`${colorClasses.backgroundPrimary}`]: !image,
                }
              )}
            >
              {image && (
                <Photo image={image} fill imageClassName="object-cover" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MaskedCircleSection
